import { render, staticRenderFns } from "./Sitemap.vue?vue&type=template&id=ddd42e8c&scoped=true&"
import script from "./Sitemap.vue?vue&type=script&lang=ts&"
export * from "./Sitemap.vue?vue&type=script&lang=ts&"
import style0 from "./Sitemap.vue?vue&type=style&index=0&id=ddd42e8c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ddd42e8c",
  null
  
)

export default component.exports